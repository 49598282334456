import React from "react"
import Layout from "../components/layout"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import SolarAPUImage from "../images/solar-apu-unit.png"
import SolarAPUBanner from "../images/solar-apu-banner-trucks.png"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import Blue from "@material-ui/core/colors/blue"

const SolarAPU = () => {
  const createRow = (name, description) => {
    return { name, description }
  }

  const rows = [
    createRow("Model", "VDC20F1/GU"),
    createRow("Voltage", "DC12V"),
    createRow("Cooling Capacity", "2500 Watts 8600 Btu/h"),
    createRow("Refrigerant/ Charge Volume", "R134a/ 1.85LBs"),
    createRow("Standard Total Current", "70A"),
    createRow("Compressor Displacement", " 18 cc/fev"),
    createRow("Compressor Refrigeration Oil", "Zeroal Easter 68SL/100cc"),
    createRow("Compressor Rated RPM", "2000-5500"),
    createRow("Evaporator Type", "Copper Tube & Aluminum Fins"),
    createRow("Evaporator Cool Air Flow", "250 CFM"),
    createRow("Condensor Type", "Multi-flow Aluminum"),
    createRow("Condensor Air Flow", "1100 CFM"),
    createRow("Control System Inside Temperature", "15-30C Adjustable"),
    createRow("Control System Defrost Temperature", "1~2C"),
    createRow("Control System Wind Speed", "3 Steps"),
    createRow("Control System Compound Switch", "250 PSI OFF"),
    createRow("Control System Pressure Protection", "/5 PSI OFF"),
    createRow("Power Loss Under Voltage Protection", "10.5V"),
    createRow("Power Loss Restratvoltage Setting", "11.5V"),
    createRow("Ambient Temperature", "-30 ~ 60C"),
    createRow("Unit Weight Outdoor Unit", "65LBS(29.5kgs)"),
    createRow("Unit Weight Indoor Unit", "27.5LBS(12.5 kgs)"),
    createRow("Unit Oversize Outdoor Unit", "30'x27'x7'(750 x 685 x 185mm)"),
    createRow(
      "Unit Oversize Indoor Unit",
      "16' x 11' x 7.1'(405 x 280 x 180mm)"
    ),
  ]
  return (
    <Layout>
      <img src={SolarAPUBanner} style={{ paddingTop: "2rem" }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          src={SolarAPUImage}
          style={{ height: "5rem", paddingRight: "1rem" }}
        />

        <h1 style={{ paddingTop: "2rem", textAlign: "center" }}>
          Solar APU System
        </h1>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", fontSize: "16px" }}>
        <p>
          Complete DC Electric, operated by vehicle's battery. High efficiency,
          high reliability, batter power monitor, low noise, easy installation,
          easy operation, engine no idling, no wearing, no vibration, no fuel
          consumption, no air pollution.
        </p>
      </div>
      <p>
        Installation Process: Mount the outdoor back-wall condenser unit at the
        rear of the cab, mount the indoor cool unit under the bunk. No need to
        cut large openings, only one small hole is needed for the refrigerant
        hoses, drain pipe, and electric cabling.
      </p>
      <p>
        Wide-range application. The no-idle vehicle A/C is designed to run on
        the normal starter batteries, it can be widely applied to various
        vehicles: sleeper trucks, daycab, RV, boats, electric vehices, offroad
        heavy duty machineries, agricultural vehicles, mini buses and finally
        vans.
      </p>

      <h2>APU System With Solar Technology</h2>
      <p>
        With the state of the art technology and exceptional solar quality,
        combine your APU with solar. Approved by the U.S. Government to receive
        up to 30% Tax Credit.
      </p>
      <Link to="/#contact-us">
        <Button
          variant="contained"
          style={{
            marginBottom: "2rem",
            background: "#2196f3",
            color: "white",
          }}
        >
          Contact Us
        </Button>
      </Link>
      <TableContainer component={Paper}>
        <Table style={{ marginBottom: 0 }}>
          <TableHead>
            <TableCell
              align="left"
              style={{
                fontWeight: "bold",
                paddingLeft: "2rem",
                borderBottom: "0 !important",
                fontSize: "1.3em",
              }}
            >
              Solar APU System Technical Specifications
            </TableCell>
          </TableHead>
          <TableBody style={{ fontSize: "0.8em" }}>
            {rows.map(row => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ paddingLeft: "2rem" }}
                >
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  )
}

export default SolarAPU
