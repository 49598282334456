import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LALogo from "../images/la.jpeg"

const Header = () => (
  <header
    style={{
      background: `#172646`,
      background: "linear-gradient(#172646 0%, #1F335D 100%)",
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: "100%",
        padding: `1rem`,
        display: "flex",
        justifyCenter: "center",
        alignItems: "center",
      }}
    >
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        <img
          src={LALogo}
          style={{ maxHeight: "4rem", margin: 0, padding: 0 }}
        />
      </Link>
      <a
        style={{ color: "white", marginLeft: "3rem", textDecoration: "none" }}
        href="/"
      >
        Home
      </a>
      <a
        style={{ color: "white", marginLeft: "3rem", textDecoration: "none" }}
        href="/#contact-us"
      >
        Contact Us
      </a>
      <a
        style={{ color: "white", marginLeft: "3rem", textDecoration: "none" }}
        href="/solar-apu"
      >
        Solar APU
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
